import prettyNumber from "../../../functions/prettyNumber";

export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "ID",
      value: row?.id,
      className: "id",
    },

    {
      source: "indeks",
      name: "Indeks",
      value: row?.indeks,

      style: { width: "140px" },
    },
    {
      source: "nazwa",
      name: "Nazwa",
      value: row?.nazwa,
    },
    {
      source: "Grupa",
      name: "Grupa",
      value: row?.Grupa,
    },
    {
      source: "jm",
      name: "Jm",
      value: row?.jm,
    },
    {
      source: "vat",
      name: "VAT",
      style: {
        width:"40px"
      },
      value: prettyNumber(row?.vat, { postfix: "%", round: 1 }),
    },
    {
      source: "m_cena",
      name: "Cena",
      style: {
        width:"70px"
      },
      value: prettyNumber(row?.m_cena, { postfix: "zł" }),
    },
    {
      source: "m_cena_netto",
      name: "Cena netto",
      style: {
        width:"70px"
      },
      value: prettyNumber(row?.m_cena_netto, { round: 2 }),
    },
    {
      source: "data_modyfikacji",
      name: "Data modyfikacji",
      style: { width: "170px" },
      value: row?.data_modyfikacji?.slice(0, 16),
    },
  ];
}
