import { useState } from "react";
import Loading from "../../../common/Loading";
import useGeneric from "../../../dataFetch/useGeneric";
import prettyNumber from "../../../functions/prettyNumber";
import prettyDate from "../../../functions/prettyDate";

export default function PurchaseInvoiceView({ data }: { data: any }) {
  const [id, setID] = useState(data?.id);
  const [sellerDetails, setSellerDetails] = useState(false);

  const { rows: inv, loading: loadingInv } = useGeneric({
    name: "purchase_invoices",
    query: {
      id: id,
    },
    order: {
      by: "id",
      dir: "ASC",
    },
    limit: 1,
  });
  const { rows, loading } = useGeneric({
    name: "purchase_invoice_details",
    query: {
      id_factures: id,
    },
    order: {
      by: "sort_position",
      dir: "ASC",
    },
  });

  const invoice = !loadingInv && inv?.[0];

  const { rows: bankRows, loading: loadingBank } = useGeneric({
    name: invoice?.name && "system2_bank",
    query: {
      relation_object_name: invoice?.name,
    },
    order: {
      by: "id",
      dir: "ASC",
    },
  });

  const { rows: cashRows, loading: loadingCash } = useGeneric({
    name: invoice?.name && "system2_cash",
    query: {
      relation_object_name: invoice?.name,
    },
    order: {
      by: "id",
      dir: "ASC",
    },
  });

  const items = !loading && rows;

  const bank = !loadingBank && bankRows;
  const cash = !loadingCash && cashRows;

  function PayDocs({ type }: { type: "bank" | "cash" }) {
    const source = type === "bank" ? bank : cash;

    return (
      <>
        {source?.map((b: any) => (
          <div
            title={b?.remarks + "\r\n" + "Data utworzenia: " + b?.create_date}
          >
            <a
              style={{ color: "black", textDecoration: "none" }}
              target="_blank"
              href={
                "https://system.agit.com.pl/web/" +
                type +
                ",view," +
                b?.id +
                ".html"
              }
            >
              {b?.name}{" "}
            </a>
            <span style={{ fontSize: "80%", color: "grey" }}>
              {prettyNumber(b?.price, {
                postfix:
                  b?.currencies_name === "Złoty" ? "zł" : b?.currencies_name,
                round: 2,
              })}
            </span>
          </div>
        ))}
      </>
    );
  }
  function ChangeId() {
    return (
      <div
        style={{
          display: "flex",
          gap: "5px",
          marginRight: "10px",
          userSelect: "none",
          color: "lightgrey",
        }}
      >
        <div
          style={{ padding: "0 5px", cursor: "pointer" }}
          onClick={() => setID((prev: number) => prev - 1)}
          title={String(invoice?.id - 1)}
        >
          {"<"}
        </div>
        <div
          style={{ padding: "0 5px", cursor: "pointer" }}
          title={String(invoice?.id + 1)}
          onClick={() => setID((prev: number) => prev + 1)}
        >
          {">"}
        </div>
      </div>
    );
  }
  return loading || loadingInv ? (
    <Loading />
  ) : invoice ? (
    <div className="purchase-invoice">
      <div>
        <div
          style={{
            display: "grid",
            gridGap: "12px",
            padding: "12px",
            gridTemplateColumns: "4fr 5fr",
          }}
        >
          <div>
            <div
              style={{
                fontSize: "19px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <a
                style={{ color: "black", textDecoration: "none" }}
                target="_blank"
                href={
                  "https://system.agit.com.pl/web/factures,view," + id + ".html"
                }
              >
                <div title={invoice?.id}>{invoice?.name}</div>
              </a>
              <ChangeId />
            </div>
            <div style={{ fontSize: "75%", color: "grey" }}>
              {invoice?.factures_types_description}{" "}
              {invoice?.id_factures_types === 48 ? (
                <span
                  onClick={() => setID(invoice?.id_factures)}
                  style={{ cursor: "pointer", color: "black" }}
                >
                  {invoice?.factures_name}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div
              onClick={() => setSellerDetails((prev: boolean) => !prev)}
              style={{
                marginTop: "10px",
                cursor: "pointer",
                textWrap: "balance",
              }}
            >
              <span style={{ fontSize: "125%" }}>
                {invoice?.seller_name?.replace(/(i) /g, "$1\u00a0")}{" "}
              </span>
              {sellerDetails ? (
                <div
                  style={{
                    fontSize: "85%",
                    marginBlock: "4px",
                    padding: "0px 10px",
                    borderLeft: "1px solid grey",
                    color: "grey",
                  }}
                >
                  <div>
                    {invoice?.seller_first_name} {invoice?.seller_last_name}
                  </div>
                  <div>
                    {invoice?.seller_street}{" "}
                    {invoice?.seller_street_home_number}
                    {invoice?.seller_street_local_number ? (
                      <>/{invoice?.seller_street_local_number}</>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    {invoice?.seller_postal_code} {invoice?.seller_city}
                  </div>
                  <a
                    target="_blank"
                    href={
                      "https://system.agit.com.pl/web/customers,view," +
                      invoice?.id_customers_seller +
                      ".html"
                    }
                  >
                    Link do podmiotu w systemie
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div style={{ fontSize: "75%" }}>
              {invoice?.seller_nip ? (
                <>
                  <span style={{ color: "grey" }}>NIP</span>{" "}
                  <span style={{ color: "green" }}>{invoice?.seller_nip}</span>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <div className="cat">
              <span className="cat-name">Kategoria</span>
              <span>
                {invoice?.factures_categories_name}
                {invoice?.factures_subcategories_name ? (
                  <>
                    {" > "}
                    {invoice?.factures_subcategories_name}
                  </>
                ) : (
                  <></>
                )}
              </span>
            </div>
            <div className="cat">
              <span className="cat-name">Data otrzymania</span>
              {prettyDate(invoice?.received_date, "", true, { year: "always" })}
            </div>
            <div className="cat">
              <span className="cat-name">Dok. źródłowy</span>
              <span>
                {invoice?.source_doc_name}{" "}
                <span style={{ fontSize: "11px", color: "grey" }}>
                  {" "}
                  {prettyDate(invoice?.source_doc_date, "", true, {
                    year: "always",
                  })}
                </span>
              </span>
            </div>
            <div className="cat">
              <span className="cat-name">Status</span>
              {invoice?.status}
            </div>

            <div className="cat" style={{alignItems:"baseline"}}>
              <span className="cat-name">Płatności</span>
              {loadingBank || loadingCash ? (
                <Loading little={true} />
              ) : !bank?.length && !cash.length ? (
                <span style={{ color: "grey" }}>brak</span>
              ) : (
                <div>
                  {bank?.length ? <PayDocs type="bank" /> : <></>}
                  {cash?.length ? <PayDocs type="cash" /> : <></>}
                </div>
              )}
            </div>
            <div style={{ fontSize: "75%", padding: "7px", color: "grey" }}>
              {invoice?.payment_name}
            </div>
            <div
              style={
                invoice?.correction_reason
                  ? { fontSize: "100%", padding: "7px", color: "brown" }
                  : {}
              }
            >
              {invoice?.correction_reason}
            </div>
            <div
              style={
                invoice?.comment
                  ? { fontSize: "80%", padding: "7px", color: "black" }
                  : {}
              }
            >
              {invoice?.comment}
            </div>
          </div>
        </div>
        <div className="cat-group">
          <div className="cat">
            <span className="cat-name">Netto</span>
            {prettyNumber(invoice?.net_value, { postfix: "zł", round: 2 })}
          </div>
          <div className="cat">
            <span className="cat-name">VAT</span>
            {prettyNumber(invoice?.tax_value, { postfix: "zł", round: 2 })}
          </div>
          <div className="cat">
            <span className="cat-name">Brutto</span>
            <span style={{ fontWeight: "bold" }}>
              {prettyNumber(invoice?.gross_value, { postfix: "zł", round: 2 })}
            </span>
          </div>
          {invoice?.remaining_value ? (
            <div className="cat">
              <span className="cat-name" style={{ color: "brown" }}>
                Do zapłaty
              </span>
              <span
                style={{
                  display: "inline-block",
                  color: "brown",
                  background: "#d0dcd7",
                  borderRadius: "5px",
                  padding: "2px 12px",
                  scale: "1.4",
                  transformOrigin: "0 100%",
                  fontWeight: "bold",
                  boxShadow: "1px 7px 9px -3px rgba(0,0,0,.3)",
                  width: "max-content",
                  marginRight: "10px",
                }}
              >
                {prettyNumber(
                  String(invoice?.remaining_value)?.replace(/-/, ""),
                  {
                    postfix: "zł",
                    round: 2,
                  }
                )}
              </span>
            </div>
          ) : (
            <div
              style={{
                background: "lightgreen",
                padding: "3px 10px",
                fontWeight: "bold",
              }}
            >
              ZAPŁACONA{" "}
              <span style={{ fontSize: "90%", fontWeight: "normal" }}>
                {invoice?.payed_kind}
              </span>
            </div>
          )}
        </div>
      </div>
      <table className="data-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Pozycja</th>
            <th>Ilość</th>
            <th>Jm.</th>
            <th>Wartość netto</th>
            <th>Wartość brutto</th>
            <th>VAT</th>
            <th>Ostatnia zmiana</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items?.length &&
            items?.map((i: any) => (
              <tr
                style={
                  i?.before === 1
                    ? { textDecoration: "line-through", color: "brown" }
                    : {}
                }
              >
                <td>
                  {i?.before === 1 && i?.id_correction_factures_item ? (
                    <span style={{ color: "brown" }}>
                      {i?.id_correction_factures_item}
                    </span>
                  ) : (
                    <>{i?.id}</>
                  )}
                </td>
                <td>{i?.name}</td>
                <td>{i?.quantity}</td>
                <td>{i?.unit_name}</td>
                <td>{i?.net_price}</td>
                <td>{i?.gross_price}</td>
                <td>{i?.tax_name}</td>
                <td>{i?.last_change_person || i?.create_person}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div style={{ padding: "40px" }}>
      <div
        style={{
          fontSize: "19px",
          width: "100%",
          textAlign: "center",
          fontWeight: "bold",
          gap: "40px",
        }}
      >
        Brak faktury <ChangeId />
      </div>
    </div>
  );
}
