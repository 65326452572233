import { nameTypes } from "../../dataFetch/genericCaseMap";
import useGeneric from "../../dataFetch/useGeneric";
import { CollectionContext } from "./CollectionContext";
import CollectionItem from "./CollectionItem";
import CollectionWrap from "./CollectionWrap";
import { cells } from "./types";

export default function Collection({
  source,
  cells,
}: {
  source: keyof typeof nameTypes | "";
  cells: cells;
}) {
  const { rows, loading } = useGeneric({ name: source });
  const displayStyle = "table";
  //   const cells = ["id", "name", "year"];

  return !loading && rows?.length ? (
    <CollectionContext.Provider
      value={{
        displayStyle: displayStyle,
        cells: cells,
      }}
    >
      <CollectionWrap>
        {rows?.map((item: any) => {
          return <CollectionItem item={item} cells={cells} />;
        })}
      </CollectionWrap>
    </CollectionContext.Provider>
  ) : (
    <></>
  );
}
