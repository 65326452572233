import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/UserContext";
import VoucherListGroups from "./VoucherListGroups";
import VoucherPDF from "./VoucherPDF";
import VoucherDetails from "./VoucherDetails";
import VoucherIndividualView from "./VoucherIndividualView";
import useGeneric from "../dataFetch/useGeneric";
import { compareVoucherCount } from "./VoucherCountLSI";
import waitingIcon from "../../images/icons/waiting.svg";
import alertIcon from "../../images/icons/alert.svg";
import { ViewContext } from "../contexts/ViewContext";

export default function VoucherGroupRegister({
  items,
  register,
  loading,
  update,
  setUpdate,
  lsiCount,
}: {
  items: any;
  register: any;
  loading: Boolean;
  update: number;
  setUpdate: Function;
  lsiCount: any;
}) {
  const { userID, auth } = useContext(UserContext);
  const { setModal } = useContext(ViewContext);
  const [currentAmount, setCurrentAmount] = useState<any>();
  const [currentPaid, setCurrentPaid] = useState<any>();
  const [currentItem, setCurrentItem] = useState<any>();
  const i = auth === 9 ? [...items?.slice()?.reverse(), ...register] : register;

  useEffect(() => {
    if (update > 0 && currentItem) {
      //   console.log(
      //     currentItem?.id,
      //     register?.find((i: any) => i?.id === currentItem?.id)?.id
      //   );
      setCurrentItem(register?.find((i: any) => i?.id === currentItem?.id));
    }
  }, [update]);

  useEffect(() => {
    if (window.innerWidth < 900 && currentItem) {
      setModal({
        show: true,
        heading: "Opcje vouchera",
        content: (
          <VoucherIndividualView
            item={currentItem}
            setUpdate={setUpdate}
            update={update}
          />
        ),
      });
    }
  }, [currentItem]);

  function getAvailableVoucherNumber(i: any, amount: number, paid: boolean) {
    const left = i?.filter(
      (i: any) => i?.amount === amount && i?.paid == paid && i?.status === 2
    )?.length;
    const waiting = i?.filter(
      (i: any) => i?.amount === amount && i?.paid == paid && i?.status === 1
    )?.length;
    const all = i?.filter(
      (i: any) => i?.amount === amount && i?.paid == paid
    )?.length;

    return (
      <div
        onClick={() => {
          setCurrentAmount(amount);
          setCurrentPaid(paid);
        }}
        className={
          amount === currentAmount && currentPaid == paid ? "active" : ""
        }
      >
        {left > 0 || waiting > 0 ? (
          <>
            {amount} zł{" "}
            {left ? (
              <>
                <span
                  className="v-number"
                  style={{
                    color: left < 3 ? "red" : left < 6 ? "#bd9e1e" : "green",
                  }}
                >
                  ({left}){" "}
                </span>
              </>
            ) : (
              <></>
            )}
            {waiting > 0 ? (
              <img
                src={waitingIcon}
                style={{ width: "10px", opacity: 0.25, paddingLeft: "5px" }}
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <span style={{ opacity: all ? 1 : 0.3 }}>{amount} zł</span>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="voucher-alert">
        {lsiCount &&
          lsiCount?.map((l: any) => {
            const { countLSI, countHapp } = compareVoucherCount(l, register);
            return countLSI !== countHapp ? (
              <div className="voucher-alert-text">
                {countLSI - countHapp > 0 ? (
                  <>
                    <img src={alertIcon} />
                    {l.nazwa_skrocona} | Liczba voucherów w LSI wyższa o{" "}
                    {countLSI - countHapp}
                  </>
                ) : countLSI - countHapp < 0 ? (
                  <>
                    <img src={alertIcon} />
                    {l.nazwa_skrocona} | Liczba voucherów w LSI niższa o{" "}
                    {Math.abs(countLSI - countHapp)}
                  </>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            );
          })}
        {/* <div>Uwaga!</div>
        <div>Sprzedany voucher nie został jeszcze oznaczony</div> */}
      </div>
      <div className="voucher-register-groups">
        <div className="sidebar categories">
          <div className="category">
            <div
              style={{ cursor: "pointer" }}
              className={
                "category-heading" +
                (currentAmount === undefined && currentPaid === undefined
                  ? " active"
                  : "")
              }
              onClick={() => {
                setCurrentAmount(undefined);
                setCurrentPaid(undefined);
              }}
            >
              WSZYSTKIE
            </div>
          </div>
          <div className="category">
            <div className="category-heading">PŁATNE</div>
            <div className="category-content">
              {getAvailableVoucherNumber(i, 50, true)}
              {getAvailableVoucherNumber(i, 100, true)}
              {getAvailableVoucherNumber(i, 150, true)}
              {getAvailableVoucherNumber(i, 200, true)}
            </div>
          </div>
          <div className="category">
            <div className="category-heading">GRATISOWE</div>
            <div className="category-content">
              {getAvailableVoucherNumber(i, 50, false)}
              {getAvailableVoucherNumber(i, 100, false)}
              {getAvailableVoucherNumber(i, 150, false)}
              {getAvailableVoucherNumber(i, 200, false)}
            </div>
          </div>
        </div>
        <div className="mainview">
          <VoucherListGroups
            key={currentAmount + (currentPaid ? "p" : "g")}
            setUpdate={setUpdate}
            auth={auth}
            loading={loading}
            items={i}
            //   heading="150 zł"
            amount={currentAmount}
            paid={currentPaid}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
          />
        </div>
        <div className="voucher-individual-view">
          {currentItem ? (
            <VoucherIndividualView
              item={currentItem}
              setUpdate={setUpdate}
              update={update}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
