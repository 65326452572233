import useOptions from "../forms_new/Elements/useOptions";

export default function Filters({
  schema,
  setQuery,
  className,
}: {
  schema: any;
  setQuery: Function;
  className?: string;
}) {
  const loadOptions: { data: any; loading: Boolean } = useOptions(schema);
  const fetchedOptions: any = !loadOptions?.loading && loadOptions?.data;

  return (
    <div className={"filters" + (className ? " " + className : "")}>
      {schema?.map((item: any, index: number) => {
        const { exact, defaultValue } = item;
        const options: any =
          item.element === "select" &&
          (fetchedOptions && fetchedOptions?.[item?.getOptionsFrom]?.rows
            ? fetchedOptions?.[item?.getOptionsFrom]?.rows
            : item?.optionList);

        console.log(options, item.column);
        return (
          <div className="filter">
            <label>{item.name}</label>
            {item?.element === "select" ? (
              <select
                onChange={(e: any) => {
                  if (e.target.value !== undefined) {
                    setQuery((prev: any) => ({
                      ...prev,
                      [item?.column]: e.target.value,
                    }));
                  }
                }}
              >
                <option value={undefined}></option>
                {options?.map((option: any) => (
                  <option value={option?.id}>{option?.name}</option>
                ))}
              </select>
            ) : (
              <>
                <input
                  list={item?.suggestions ? item.name : undefined}
                  defaultValue={defaultValue}
                  onChange={(e: any) => {
                    if (
                      e.target.value &&
                      item?.suggestions &&
                      !item?.suggestions?.includes(
                        Number.isInteger(parseInt(e.target.value))
                          ? Number(e.target.value)
                          : e.target.value
                      )
                    ) {
                      return;
                    }
                    if (e.target.value.length > 2) {
                      setQuery((prev: any) => ({
                        ...prev,
                        [item?.column]: exact
                          ? e.target.value
                          : [e.target.value, "fuzzy"],
                      }));
                    } else if (e.target.value.length < 3) {
                      setQuery((prev: any) => ({
                        ...prev,
                        [item?.column]: undefined,
                      }));
                    }
                  }}
                  type={item?.type ? item.type : "search"}
                />
                {item?.suggestions ? (
                  <datalist id={item.name}>
                    {item?.suggestions?.map((s: any) => (
                      <option value={s}>{s}</option>
                    ))}
                  </datalist>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
