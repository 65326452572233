const fetchAddresses = {
  errorTable: "get-error-table.php",
  getFiles: "get-files.php",
  showError: "error-table/get-single-error.php",
  problemTypes: "error-table/get-problem-types.php",
  salesDocumentTypes: "error-table/get-sales-document-types.php",
  reportingFormTypes: "error-table/get-reporting-form-types.php",
  whatIsCorrected: "error-table/get-what-is-corrected.php",
  correctionSigned: "error-table/get-correction-signed.php",
  employees: "error-table/get-employees.php",
  employeesGeneric: "error-table/get-employees-generic.php",
  employeesHybridField: "error-table/get-employees-search.php",
  employeesAdvanced: "error-table/get-employees-advanced.php",
  employeeGroups: "error-table/get-employee-groups.php",
  paymentFormsList: "get-payment-forms-list.php",
  paymentFormChange: "get-payment-form-change.php",
  users: "get-users.php",
  discussion: "get-discussion.php",
  discussionCategories: "get-discussion-categories.php",
  onlineUsers: "get-online-users.php",
  userList: "get-all-users.php",
  auth: "get-auth.php",
  sessions: "get-sessions.php",
  transferReceipts: "error-table/get-transfer-receipts.php",
  events: "error-table/get-events.php",
  "event-calendar": "get-event-calendar.php",
  "event-calendar-potential": "get-potential-events.php",
  "event-calendar-simple": "get-event-calendar-simple.php",
  "search-event-calendar": "search-event-calendar.php",
  "event-calendar-basic": "get-event-calendar-tv.php",
  "event-calendar-archive": "get-event-calendar.php",
  "event-calendar-history": "get-calendar-history.php",
  venues: "get-venues.php",
  attendance: "get-attendance.php",
  attendance_3maja: "get-attendance.php",
  cards: "get-cards.php",
  attendancePairs: "get-attendance-pairs.php",
  attendanceDetails: "get-attendance-details.php",
  attendanceLatest: "get-attendance-latest.php",
  holidayRanges: "get-holiday-ranges.php",
  holidays: "get-holidays.php",
  holidaysSuggested: "get-holidays-suggested.php",
  holidayCount: "get-holiday-count.php",
  holidayCountAtDate: "get-holiday-count-at-date.php",
  holidayCountView: "get-holiday-count-view.php",
  holidayTypes: "get-holiday-types.php",
  holidayRequests: "get-holiday-requests.php",
  holidayReport: "get-holiday-report.php",
  holidayReportByPerson: "get-holiday-report-by-person.php",
  annualAttendanceReport: "get-annual-sums.php",
  getUserByCode: "get-user-by-code.php",
  attendanceSums: "get-attendance-sums.php",
  attendanceAnnualSum: "get-attendance-annual-sum.php",
  "attendance-now": "get-attendance-now.php",
  holidaySums: "get-holiday-sums.php",
  schedules: "schedules/get-schedules.php",
  scheduleData: "schedules/get-schedule-data.php",
  scheduleTemplates: "schedules/get-templates-specials.php",
  nip: "gus-api/queryM.php",
  p24: ["full", "https://happ.agit.com.pl/backend/p24/create-link.php"],
  calendar_auth: "get-calendar-auth.php",
  get_version: "get-version.php",
  event_calendar_payments: "get-event-payments.php",
  employee_medicals: "get-medicals.php",
  employee_bhp: "get-bhp.php",
  employee_sanitary: "get-sanitary.php",
  employee_contracts: "get-employee-contracts.php",
  uploads_list: "get-uploads-list.php",
  contract_types: "get-contract-types.php",
  employee_dates_incoming: "get-incoming-employee-dates.php",
  employee_dates_incoming_sorted: "get-incoming-employee-dates-sorted.php",
  vouchers: "get-vouchers.php",
  purchase_invoices: "external/get-purchase-invoices.php",
  purchase_invoice_details: "external/get-purchase-invoice.php",
  system2_bank: "external/get-bank.php",
  system2_cash: "external/get-cash.php",
  "get-log": "get-log.php",
  "invoice-mail-preview": "mail/invoice-mail-preview.php",
  "voucher-register-memory": "get-voucher-memory.php",
  "voucher-register": "get-voucher-register.php",
  management: "get-management.php",
  "calendar-report": "get-event-calendar-report.php",
  "check-names": "check-name.php",
  "restaurant-menus": "get-restaurant-menus.php",
  "restaurant-menu-single": "get-restaurant-menu-single.php",
  "restaurant-menu-categories": "get-restaurant-menu-categories.php",
  "get-tv-schedule": "get-tv-schedule.php",
  "ask-gpt": "chat-gpt-api/user/ask.php",
  "config-tv": "get-config-tv.php"
};

const fetchLSIaddresses = {
  lsi_invoice_query: "query_invoices.php",
  lsi_receipt_query: "query_receipts.php",
  lsi_receipt_query_source: "query_receipts_source.php",
  lsi_transactions: "query_transactions.php",
  lsi_guests_by_invoice: "query_guests_by_invoice.php",
  lsi_restaurant: "query_restaurant.php",
  lsi_room_definitions: "query_room_definitions.php",
  lsi_products: "query_products.php",
  lsi_product_groups: "query_product_groups.php",
  lsi_rooms: "query_rooms.php",
  lsi_sessions: "query_sessions.php",
  lsi_profitroom: "query_profitroom.php",
  lsi_bookings: "query_bookings.php",
  lsi_bookings_today: "query_bookings_today.php",
  lsi_vouchers: "query_vouchers.php",
  lsi_voucher_changes: "query_voucher_changes.php",
  lsi_clients: "query_clients.php",
  lsi_locale: "query_locale.php",
  lsi_deliveries: "query_deliveries.php",
  lsi_stats: "query_stats.php",
  lsi_incomings: "query_incomings.php",
  lsi_balance_list: "query_balance_list.php",
  lsi_sales_stats: "query_sales.php",
  lsi_product_details: "query_product_details.php",
};

export const nameTypes = { ...fetchAddresses, ...fetchLSIaddresses };

export default function genericCaseMap(name: keyof typeof nameTypes | "") {
  let fetchAddress = "";
  let fetchAddressFull = "";

  const lsiAddresses = {
    production: "https://data.agit.com.pl/hotelhub/backend/lsi/",
    development: "https://data.agit.com.pl/hotelhub/backend/lsi_dev/",
  };

  const lsi = fetchLSIaddresses?.[name as keyof typeof fetchLSIaddresses];
  if (lsi) {
    fetchAddressFull =
      (process.env.NODE_ENV === "production"
        ? lsiAddresses.production
        : lsiAddresses.development) +
      fetchLSIaddresses?.[name as keyof typeof fetchLSIaddresses];
  }

  const selectedAddress = fetchAddresses?.[name as keyof typeof fetchAddresses];
  fetchAddress = typeof selectedAddress === "string" ? selectedAddress : "";

  if (Array.isArray(selectedAddress)) {
    if (selectedAddress?.[0] === "full") {
      fetchAddressFull = selectedAddress?.[1];
    }
  }

  return { fetchAddress, fetchAddressFull };
}
