import loadingSpinner from "../../images/loading.svg";
import LoadingBars from "../../images/bars.svg";

export default function Loading(props: {
  absolute?: boolean;
  little?: boolean;
  back?: boolean;
  title?: String;
  overlay?: boolean;
  style?: React.CSSProperties;
}) {
  return props.little ? (
    <div style={{ display: "flex", alignItems: "center", ...props?.style }}>
      <img width="24" src={loadingSpinner} />
      <div style={{ marginLeft: "9px" }}>
        {props?.title ? props.title : <>Pobieranie</>}
      </div>
    </div>
  ) : (
    <div
      className={
        "loading" +
        (props.back ? " back" : "") +
        (props.absolute ? " absolute" : "") +
        (props.overlay ? " overlay" : "")
      }
      style={props?.style}
    >
      <img style={{ width: "80px", filter: "invert(1)" }} src={LoadingBars} />
      <div style={{ marginTop: "5px" }}>
        {props?.title ? props.title : <>Ładowanie</>}
      </div>
    </div>
  );
}
