import prettyNumber from "../../../functions/prettyNumber";
import { URLProd } from "../../../paths";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  return [
    { source: "", name: "Miejsce", value: <div>{row?.place}</div> },
    {
      source: "Grupa",
      name: "Grupa",
      value: <div>{row?.Grupa}</div>,
      style: {
        width: "120px",
      },
    },
    {
      source: "nazwa",
      name: "Nazwa",
      value: <div>{row?.nazwa}</div>,
      style: {
        width: "45%",
        ...(row?.indeks?.match(/^KU-.*/)
          ? {
              color: "#18528c",
              //   color: "white",
            }
          : row?.indeks?.match(/ALKO/)
          ? {
              color: "#bc7b03",
            }
          : {}),
      },
    },
    {
      source: "ilosc",
      name: "Liczba sprzedanych szt.",
      value: <>{prettyNumber(row?.ilosc, { round: 1 })}</>,
      style: {
        width:"100px"
      }
    },
    {
      source: "wartosc",
      name: "Łączna wartość sprzedaży",
      value: <>{prettyNumber(row?.wartosc, { round: 2, postfix: "zł" })}</>,
    },
    {
      source: "godzina",
      name: "Średnia godz.",
      value: <>{row?.godzina}</>,
      style: { width: "60px" },
    },
    {
      source: "srednia_wartosc_zamowienia",
      name: "Średnia wartość zamówienia",
      value: <>{prettyNumber(row?.srednia_wartosc_zamowienia, { round: 2, postfix:"zł" })}</>,
    },
  ];
}
