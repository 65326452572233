import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import insertGeneric from "../../dataFetch/insertGeneric";

export default function HolidayCell({
  isHoliday,
  isHolidaySuggested,
  currentHoliday,
  currentHolidaySuggested,
  employeeData,
  setHolidayOptions,
  setSmallUpdate,
  dateSQL,
  colors
}: {
  isHoliday: boolean;
  isHolidaySuggested: boolean;
  currentHoliday: any;
  currentHolidaySuggested: any;
  employeeData: any;
  setHolidayOptions: Function;
  setSmallUpdate: Function;
  dateSQL: string;
  colors?: string[];
}) {
 
  const { userID, permissions } = useContext(UserContext);
  const p = parseInt(permissions["Attendance"]);
  return (
    <div
      className={
        "holidayCell" +
        (isHoliday ? " active" : "") +
        (isHolidaySuggested ? " suggested" : "")
      }
      style={{
        background: currentHoliday?.type
          ? " " + colors?.[currentHoliday.type - 1]
          : "",
      }}
    >
      {isHoliday ? (
        <div
          className="holidayTypeSelector"
          onClick={() =>
            p > 3 ? setHolidayOptions((prev: boolean) => !prev) : null
          }
        >
          {currentHoliday.typestring?.slice(0, 15)}{" "}
          {p > 3 ? <span>▾</span> : <></>}
        </div>
      ) : (
        <></>
      )}
      {!isHoliday && isHolidaySuggested && currentHolidaySuggested?.typestring !== "zdalne" ? (
        <div className="userSuggested">
           {currentHolidaySuggested?.typestring?.slice(0, 15)} ?
        </div>
      ) : (
        <></>
      )}
      {!(p > 3) ? (
        <></>
      ) : (
        <button
          onClick={() => {
            if (employeeData && employeeData.id) {
              let confirmation = true;
              if (!employeeData?.annual_holiday_pool1 && !currentHoliday) {
                confirmation = window.confirm(
                  "Nie określono puli urlopu. Czy mimo to chcesz dodać urlop?"
                );
              }
              if (confirmation) {
                insertGeneric("holidays", {
                  approval: true,
                  date: dateSQL,
                  type: 1,
                  employeeID: employeeData.id,
                  status: currentHoliday && currentHoliday.status === 1 ? 0 : 1,
                  approved: 1,
                  personApproved: userID,
                });
                setSmallUpdate((prev: number) => prev + 1);
                setHolidayOptions(false);
              }
            }
          }}
        >
          {" "}
          {isHoliday ? "✖" : "+ DODAJ"}
        </button>
      )}
    </div>
  );
}
