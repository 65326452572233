import ReceiptDetails from "../../../calendar/ReceiptDetails";
import prettyDate from "../../../functions/prettyDate";
import prettyNumber from "../../../functions/prettyNumber";
import trailingZero from "../../../functions/trailingZero";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "Id",
      value: <>{row?.id}</>,
      className: "id",
    },
    {
      source: "pos",
      name: "POS",
      value: <>{row?.pos}</>,
    },
    {
      source: "operator",
      name: "Operator",
      value: <>{row?.operator}</>,
    },
    {
      source: "Symbol",
      name: "Magazyn",
      value: row?.Symbol,
    },
    {
      source: "full_number",
      name: "Nr dok.",
      // value: <>{row?.DocNo}</>,
      onClickModal: <ReceiptDetails id={row?.id} />,
      value: (
        <div style={{ fontSize: "110%" }}>{row?.full_number || row?.DocNo}</div>
      ),
    },
    {
      source: "klient",
      name: "Klient",
      style: {
        width: "250px",
      },
      value: <>{row?.klient}</>,
    },

    {
      source: "rozp_realizacji",
      name: "Początek transakcji",

      value: (
        <>
          {new Date(row?.rozp_realizacji?.slice(0, 16))?.toLocaleTimeString(
            "pl-PL",
            { minute: "2-digit", hour: "2-digit" }
          )}
        </>
      ),
    },
    {
      source: "koniec_realizacji",
      name: "Koniec transakcji",

      value: (
        <>
          {new Date(row?.koniec_realizacji?.slice(0, 16))?.toLocaleTimeString(
            "pl-PL",
            { minute: "2-digit", hour: "2-digit" }
          )}
        </>
      ),
    },
    // {
    //   source: "ilosc_osob",
    //   name: "Liczba osób",
    //   value: <>{row?.ilosc_osob}</>,
    // },
    // {
    //   source: "TerminPlatnosciRachunku",
    //   name: "Termin płatności",
    //   value: <>{prettyDate(row?.TerminPlatnosciRachunku)}</>,
    // },
    {
      source: "wartosc",
      name: "Wartość",
      value: (
        <div title={row?.paid_net}>
          {prettyNumber(row?.wartosc, { round: true, postfix: "zł" })}
        </div>
      ),
    },
    {
      source: "DataWplaty",
      name: "Data wpłaty",
      value: (
        <>
          {new Date(row?.DataWplaty)?.toLocaleString("pl-PL", {
            minute: "2-digit",
            hour: "2-digit",
            day: "2-digit",
            month:"2-digit",
            year:"numeric"
          })}
        </>
      ),
    },
    // {
    //   source: "paid_net",
    //   name: "Wartość netto",
    //   value: (
    //     <>
    //       {row?.paid_net &&
    //         prettyNumber(row?.paid_net, {
    //           round: true,
    //           postfix: "zł",
    //         })}
    //     </>
    //   ),
    // },
    {
      source: "paid_type",
      name: "Sposób płatnośći",
      value: <span title={row?.paid_amounts}>{row?.paid_type}</span>,
    },
    {
      source: "wystawiono_fakture",
      name: "Z fakturą",
      value: (
        <>
          {row?.wystawiono_fakture == "1" ? (
            <>
              ✓{" "}
              <span
                title={
                  row?.invoice_client + "\r\n" + row?.invoice_date?.slice(0, 10)
                }
              >
                {row?.invoice_number}/
                {trailingZero(new Date(row?.invoice_date)?.getMonth() + 1)}/
                {new Date(row?.invoice_date)?.getFullYear()}
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];
}
