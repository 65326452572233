import prettyNumber from "../../../functions/prettyNumber";

export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "ID",
      value: row?.id,
      className: "id",
    },
    {
      source: "factures_types_kind",
      name: "Typ",
      value: row?.factures_types_kind,
      style: { fontSize: "9px" },
    },
    {
      source: "factures_categories_name",
      name: "Kategoria",
      value: (
        <div style={{display:"flex", fontSize:"80%", gap:"7px"}}>
          <div>{row?.factures_categories_name}</div>
          {row?.factures_subcategories_name ? (
            <div>{" > "}{row?.factures_subcategories_name}</div>
          ) : (
            <></>
          )}
        </div>
      ),

      style: { width: "140px" },
    },
    {
      source: "name",
      name: "Numer",
      value: <div>{row?.name}</div>,

      style: { width: "140px" },
    },
    {
      source: "seller_name",
      name: "Sprzedający",
      value: row?.seller_name,
      style: { width: "320px" },
    },
    {
      source: "received_date",
      name: "Data otrzym.",
      value: row?.received_date,
    },
    {
      source: "source_doc_name",
      name: "Dok. źródłowy",
      value: row?.source_doc_name,
      style: { fontSize: "9px" },
    },
    {
      source: "status",
      name: "Status",
      value: row?.status,
      style: { fontSize: "9px" },
      rowClassName: row?.status === "opłacona" ? "checked-in" : "",
    },
    {
      source: "payment_date",
      name: "Termin płatności",
      value: row?.payment_date,
    },
    {
      source: "gross_value",
      name: "Wartość brutto",
      value: prettyNumber(row?.gross_value, { postfix: " zł" }),
    },
    {
      source: "create_person",
      name: "Użytk.",
      value: row?.create_person
        ?.split(" ")
        ?.map((n: string) => n.slice(0, 1))
        ?.join(""),
    },
  ];
}
