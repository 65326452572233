import { useState, useEffect } from "react";
import preAdjust from "../attendance/preAdjust";
import { URL } from "../paths";
import { groupBy } from "../functions/tools";
import genericCaseMap, { nameTypes } from "./genericCaseMap";

export interface paramsInt {
  name: keyof typeof nameTypes | (keyof typeof nameTypes)[] | "";
  process?: Function;
  limit?: number;
  count?: boolean;
  offset?: number;
  reload?: boolean;
  loading?: boolean;
  hiddenLoading?: boolean;
  update?: number | boolean | string;
  updateWithLoading?: number | boolean | string;
  query?: any;
  order?: {
    by: string | string[];
    dir: "ASC" | "DESC" | string | string[];
  };
  active?: boolean;
  onlyOnFocus?: boolean;
  condition?: boolean;
  secondsToReload?: number;
  additional?: any;
  groupBy?: Function;
  transform?: any;
  showLoadingOnManualUpdate?: boolean;
  abort?: boolean;
  prop?: any;
  multiParams?: {
    groupBy?: Function;
    process?: Function;
  }[];
  info?: string | number;
}

export async function getData(params: paramsInt, signal: any) {
  if (params.update === false) return;
  let queryBody: string = "",
    prep: any = "";

  const { fetchAddress, fetchAddressFull } = genericCaseMap(
    params?.name as keyof typeof nameTypes
  );
  const limit = params.limit ? params.limit : 1000;
  queryBody = JSON.stringify({
    limit: limit,
    offset: params.offset ? params.offset * limit : 0,
    query: params.query ? params.query : null,
    active: params.active ? params.active : null,
    order: params?.order ? params.order : null,
    count: params?.count !== undefined ? params.count : null,
    info: params?.info !== undefined ? params.info : null,
  });

  let fetchedData: any = {
    rows: [],
    limit: limit,
    offset: params.offset,
    // error: false,
  };
  let errorResult: any;
  try {
    const response = await fetch(
      fetchAddressFull ? fetchAddressFull : `${URL}backend/${fetchAddress}`,
      {
        method: "post",
        body: queryBody,
        credentials: "include",
        signal,
      }
    );
    if (!response.ok) {
      // throw new Error(`HTTP error! status: ${response.status}`);
    }

    fetchedData = await response.json();
  } catch (error) {
    errorResult = error;
  }
  return {
    ...fetchedData,
    rows: fetchedData.rows,
    limit: params.limit,
    offset: params.offset,
    error: errorResult?.message,
  };
}

const useGeneric = (params: paramsInt) => {
  const [countLoads, setCountLoads] = useState(0);
  const focus = params.onlyOnFocus
    ? document.visibilityState === "hidden"
      ? false
      : true
    : true;
  const condition = params.condition;
  const proceed = focus && !condition;
  const [multipleLoading, setMultipleLoading] = useState(true);
  const [data, setData] = useState({
    rows: [],
    prop: undefined,
    count: 0,
    limit: undefined,
    offset: undefined,
    loading: true,
    multiple: false as any,
    prevCount: undefined,
  });

  useEffect(() => {
    if (!proceed) return;
    if (!multipleLoading) {
      setData((prev: any) => ({
        ...prev,
        loading: false,
        hiddenLoading: false,
      }));
      //   console.log("instance: ", data);
    }
  }, [multipleLoading]);

  async function multiLoad(signal?: any) {
    //multi load
    if (Array.isArray(params.name)) {
      params.name.forEach((item: any, index: number, array: any) => {
        setMultipleLoading(true);

        getData({ ...params, name: item }, signal)
          .then((data) =>
            setData((prev: any) => ({
              ...prev,
              multiple: { ...prev.multiple, [item]: data },
            }))
          )
          .then(() => {
            // AFTER ALL IS LOADED SET MULTIPLE LOADING TO FALSE
            setCountLoads((prev: number) => prev + 1);
            if (index === array.length - 1) {
              window.setTimeout(() => setMultipleLoading(false), 250);
            }
          });
      });
      //single load
    } else {
      getData(params, signal).then((fetched) => {
        setData((prev) => ({
          ...fetched,
          prevCount: prev.count,
          time: new Date(),
        }));
        setCountLoads((prev: number) => prev + 1);
      });
    }
  }

  let waitForLoadingShow: any;

  useEffect(() => {
    // waitForLoadingShow = setTimeout(() => {
    // if (data?.loading === false) {
    setData((prev: any) => ({ ...prev, loading: true }));

    // }
    // }, 500);
    // return () => clearTimeout(waitForLoadingShow);
  }, [
    JSON.stringify(params?.query),
    params?.updateWithLoading,
    JSON.stringify(params?.name),
  ]);

  useEffect(() => {
    if (!params.name) return;
    if (!proceed) return;
    // clearTimeout(waitForLoadingShow);
    multiLoad();
    // console.log("load " + params?.name);
    if (params.reload) {
      const seconds = params.secondsToReload || 60;
      const doReload = setInterval(() => {
        setData((prev: any) => ({ ...prev, hiddenLoading: true }));
        multiLoad();
        // console.log("reload "  + params?.name);
      }, seconds * 1000);
      return () => {
        clearInterval(doReload);
      };
    }
  }, [
    JSON.stringify(params.name),
    params?.offset,
    params.reload,
    params?.condition,
    JSON.stringify(params?.additional),
    params?.update,
    params?.updateWithLoading,
    JSON.stringify(params?.query),
  ]);

  if (params.name === "attendancePairs") {
    const adjusted: any = data.rows
      ? preAdjust(data, params.additional.month, params.additional.year)
      : [];
    return {
      ...data,
      ...adjusted,
    };
  }

  let results = data.rows;
  let multiple = data?.multiple;

  if (params?.multiParams && data?.multiple) {
    // const multiParams = params?.multiParams?.map((m:any) => ({...m, data: data?.multiple }));
    const arr = Object.keys(data?.multiple)?.map((key: any) => ({
      topname: key,
      // name: params,
      ...data?.multiple[key],
    }));

    const multipleArr = arr.map((a: any) => {
      let currRows = a.rows;
      let topName = a.topname;
      if (params?.multiParams?.find((p: any) => p?.n === topName)?.groupBy) {
        currRows = groupBy(
          currRows,
          params?.multiParams?.find((p: any) => p?.n === topName)?.groupBy
        );
      }

      if (params?.multiParams?.find((p: any) => p?.n === topName)?.process) {
        currRows = params?.multiParams
          ?.find((p: any) => p?.n === topName)
          ?.process?.(currRows);
      }
      return {
        ...a,
        rows: currRows,
      };
    });
    // console.log(params?.multiParams, multipleArr);
    Object.keys(data?.multiple).forEach((key: string, index: number) => {
      multiple[key] = multipleArr?.find((m: any) => m.topname === key);
    });
    // console.log(params?.name, data?.multiple, params?.multiParams);
  }

  if (params?.groupBy) {
    results = groupBy(results, params.groupBy);
  }

  if (params?.process) {
    results = params?.process(results);
  }

  // console.log("multi", multiple);

  return {
    ...data,
    rows: results,
    multiple: multiple,
    loadCount: countLoads,
    // rows: params.process
    //   ? params.process(data.rows)
    //   : params.groupBy
    //   ? groupBy(data.rows, params.groupBy)
    //   : data.rows,
  };
};

export default useGeneric;
