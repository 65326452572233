import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import prettyNumber from "../functions/prettyNumber";
import BalanceGroup from "./BalanceGroup";

export default function BalanceList() {
  const { rows, loading } = useGeneric({
    name: "lsi_balance_list",
    reload: true,
    secondsToReload: 70,
  });
  const groups: any = {};
  if (rows) {
    rows?.forEach((r: any) => {
      if (groups[r.konto1]) {
        groups[r.konto1].push(r);
      } else {
        groups[r.konto1] = [r];
      }
    });
  }
  const groupKeys = Object.keys(groups);
  groupKeys.sort((a: string, b: string) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  const groupArr = groupKeys?.map((k: string) => groups[k]);

  console.log("garr", groupArr);

  const sum_owed =
    rows?.length &&
    rows
      ?.map((i: any) => Number(i?.winien))
      ?.reduce((a: number, b: number) => a + b, 0);
  const sum_has =
    rows?.length &&
    rows
      ?.map((i: any) => Number(i?.ma))
      ?.reduce((a: number, b: number) => a + b, 0);

  return loading ? (
    <Loading />
  ) : (
    <div>
      <div style={{ display: "flex", gap: "24px", alignItems: "center" }}>
        <h1>Suma</h1>
        <div>
          Winien:{" "}
          <strong style={{ fontSize: "18px" }}>
            {prettyNumber(sum_owed, { postfix: "zł", round: 2 })}
          </strong>
        </div>
        <div>
          Ma:{" "}
          <strong style={{ fontSize: "16px" }}>
            {prettyNumber(sum_has, { postfix: "zł", round: 2 })}
          </strong>
        </div>
      </div>
      {groupArr?.map((g: any) => (
        <BalanceGroup g={g} key={g?.[0]?.id} />
      ))}
    </div>
  );
}
