import { useContext, useEffect, useState } from "react";
import insertGeneric from "../dataFetch/insertGeneric";
import prettyDate from "../functions/prettyDate";
import { UserContext } from "../contexts/UserContext";
import { objectOrJSONparse } from "../functions/tools";

export default function VoucherIndividualView({
  item,
  setUpdate,
  update,
}: {
  item: any;
  setUpdate: Function;
  update: number;
}) {
  const {
    id,
    number,
    paid,
    amount,
    number_text,
    valid_until,
    request_user,
    status: statusPick,
    print_date,
    sold_date,
    client_used_date,
    received_date,
    received_user_id,
  } = item;

  const { auth: authAll } = useContext(UserContext);
  const auth = objectOrJSONparse(objectOrJSONparse(authAll)?.modules)?.vouchers;
  const [status, setStatus] = useState(statusPick);

  async function updateStatus(id: number, toStatus: number, revert?: Boolean) {
    await insertGeneric("set-voucher-status-individual", {
      id: id,
      status: toStatus,
      revert: revert,
    });

    if (!paid && (toStatus === 3 || toStatus === 2)) {
      await insertGeneric("update-lsi-voucher-number", {
        vouchers: [
          {
            amount: amount,
            paid: paid,
            copies: 1,
            group_info: number_text,
          },
        ],
        remove: toStatus === 3 ? true : undefined,
      });
    }

    setUpdate((prev: number) => prev + 1);
    setStatus(toStatus);
  }

  useEffect(() => {
    setStatus(statusPick);
  }, [id]);

  return (
    <div className="voucher-view-wrap">
      <div className="voucher-view">
        <div className="voucher-number">{number_text}</div>
        <div className="details-and-options">
          <div>
            <div className="voucher-item">
              <div>Osoba zamawiająca</div>
              <div>{request_user}</div>
            </div>
            <div className="voucher-item">
              <div>Termin ważności</div>
              <div>{prettyDate(valid_until, "", true)}</div>
            </div>
            <div className="voucher-item">
              <div>Data wydruku</div>
              <div>{status >= 1 && prettyDate(print_date)}</div>
            </div>
            <div className="voucher-item">
              <div>Data przyjęcia w hotelu</div>
              <div>{status >= 2 && prettyDate(received_date)}</div>
            </div>
            <div className="voucher-item">
              <div>Data wydania klientowi</div>
              <div>{status >= 3 && prettyDate(sold_date)}</div>
            </div>
            <div className="voucher-item">
              <div>Data wykorzystania przez klienta</div>
              <div>{status === 4 && prettyDate(client_used_date)}</div>
            </div>
          </div>
          <div className="voucher-item-option-wrap">
            {status === 1 ? ( // do podania
              <div className="info">
                <p>
                  Jeśli voucher dotarł do hotelu, oznacz jego grupę jako
                  przyjętą.
                </p>
                <p>Spowoduje to dodanie liczby voucherów w grupie do stanu.</p>
              </div>
            ) : status === 2 ? ( //w hotelu
              <>
                <div className="info">
                  <p>
                    Voucher widnieje jako dostępny w hotelu. Jeśli trafił już do
                    klienta, kliknij poniższy przycisk. Jeśli nie jest dostępny
                    w hotelu, zmień opcje grupy.
                  </p>
                </div>
                <button
                  className="normal-button"
                  onClick={() => updateStatus(item?.id, 3)}
                >
                  {paid ? (
                    <>POTWIERDŹ SPRZEDAŻ DOKONANĄ W LSI</>
                  ) : (
                    <>ODNOTUJ PRZEKAZANIE KLIENTOWI</>
                  )}
                </button>
              </>
            ) : status === 3 ? ( // wydany klientowi )
              <div>
                <div className="info">
                  Voucher w rękach klienta, który ma czas na jego wykorzystanie
                  do: <div>{prettyDate(valid_until, "", true)}.</div>
                </div>
                <button
                  className="normal-button"
                  onClick={() => updateStatus(item?.id, 4)}
                >
                  <>ODNOTUJ WYKORZYSTANIE VOUCHERA PRZEZ KLIENTA</>
                </button>
                <button
                  className="normal-button"
                  onClick={() =>
                    window.confirm("Czy na pewno chcesz cofnąć?") &&
                    updateStatus(item?.id, 2, true)
                  }
                  style={{ fontWeight: "normal", color: "brown" }}
                >
                  <>COFNIJ - VOUCHER JEDNAK NIE ZOSTAŁ WYDANY</>
                </button>
              </div>
            ) : status === 4 ? ( // wykorzystany
              <>
                <div className="info">Klient wykorzystał już ten voucher.</div>
                <button
                  className="normal-button"
                  onClick={() =>
                    window.confirm("Czy na pewno chcesz cofnąć?") &&
                    updateStatus(item?.id, 3, true)
                  }
                  style={{ fontWeight: "normal", color: "brown" }}
                >
                  <>COFNIJ - KLIENT JEDNAK NIE WYKORZYSTAŁ VOUCHERA</>
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
