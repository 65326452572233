import { useState } from "react";
import prettyDate from "../functions/prettyDate";
import prettyNumber from "../functions/prettyNumber";

export default function BalanceGroup({ g }: { g: any }) {
  const [open, setOpen] = useState(false);
  const accountName = g?.at(0)?.konto1;
  const sumOwed = g
    ?.map((i: any) => Number(i?.winien))
    ?.reduce((a: number, b: number) => a + b, 0);
  const sumHas = g
    ?.map((i: any) => Number(i?.ma))
    ?.reduce((a: number, b: number) => a + b, 0);

  return (
    <div style={{ padding: "5px 10px" }}>
      <div
        style={{
          display: "flex",
          gap: "16px",
          fontSize: "16px",
          padding: open ? "5px" : 0,
          boxShadow: open ? "1px 1px 12px rgba(0,0,0,.3)" : "",
          cursor: "pointer",
          userSelect: "none",
          fontWeight: open ? "bold" : "normal",
          alignItems: "center",
          color: "grey",
        }}
        onClick={() => setOpen((prev: Boolean) => !prev)}
      >
        <div style={{ color: "black" }}>
          {open ? <>-</> : <>+</>} {accountName}
        </div>
        <div>{prettyNumber(sumOwed, { postfix: "zł" })}</div>
        {/* <div>{sumHas}</div> */}
        <div style={{ fontSize: "11px" }}>{g?.length} poz.</div>
      </div>
      {open ? (
        <table className="data-table midi">
          <thead>
            <th>Płatnik</th>
            <th>Winien</th>
            <th>Ma</th>
            <th>Ilość</th>
            <th>Produkt</th>
            <th>POS</th>
            <th>Data</th>
            <th>Cena brutto</th>
            <th>Cena netto</th>
            <th>Wartość brutto</th>
            <th>Wartość netto</th>
            <th>Uwaga</th>
            <th>Kod stawki</th>
          </thead>
          <tbody>
            {g?.map((i: any) => (
              <tr>
                <td>{i?.platnik}</td>
                <td>{prettyNumber(i?.winien, { postfix: "zł" })}</td>
                <td>{prettyNumber(i?.ma, { postfix: "zł" })}</td>
                <td>{i?.ilosc}</td>
                <td>{i?.produkt}</td>
                <td>{i?.pos}</td>
                <td>{new Date(i?.data_wpisu)?.toLocaleDateString()}</td>
                <td>{prettyNumber(i?.cena, { postfix: "zł", round: 2 })}</td>
                <td>{prettyNumber(i?.cena_netto, { postfix: "zł", round: 2 })}</td>
                <td>{prettyNumber(i?.wartosc, { postfix: "zł", round: 2 })}</td>
                <td>{prettyNumber(i?.wartosc_netto, { postfix: "zł", round:2 })}</td>
                <td>{i?.uwaga}</td>
                <td>{i?.KodStawki}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
}
