import { useContext, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { ViewContext } from "../contexts/ViewContext";
import { URL } from "../paths";
import { UserContext } from "../contexts/UserContext";

export default function CalendarAuthorisations() {
  const [selectedUser, setSelectedUser] = useState();
  const [newAuthData, setNewAuthData] = useState();
  const [justSaved, setJustSaved] = useState(false);
  const { modal, setModal } = useContext(ViewContext);
  const { role, godMode } = useContext(UserContext);

  const isAdminOrCreator = role === 0 || role === 9;

  const getAuthData = useGeneric({
    name: "calendar_auth",
    limit: 1000,
    update: Number(justSaved),
  });
  const authData = !getAuthData.loading && getAuthData.rows;

  const optionText = ["✕ BRAK DOSTĘPU", "✓ PODGLĄD", "✓✓✓ EDYCJA"];
  const optionTextBinary = ["✕ NIE", "✓ TAK"];

  function handleUserSelect(e: any) {
    const empty = Object.fromEntries(
      authList.map((item: any) => [item.prop, null])
    );

    empty.userID = e.target.value;

    setSelectedUser(e.target.value);
    setNewAuthData(
      (authData && authData.find((u: any) => u.userID == e.target.value)) ||
        (empty && empty.userID ? empty : undefined)
    );
  }

  const authList = [
    {
      label: "Podgląd",
      prop: "view",
      binary: true,
    },
    {
      label: "Edycja",
      prop: "edit",
      binary: true,
    },
    {
      label: "Dodawanie",
      prop: "add",
      binary: true,
    },
    {
      label: "Usuwanie",
      prop: "delete",
      binary: true,
    },
    {
      label: "Usuwanie własnych",
      prop: "delete_own",
      binary: true,
    },
    {
      label: "Blokowanie",
      prop: "lock",
      binary: true,
    },
    {
      label: "Wyszukiwanie",
      prop: "search",
      binary: true,
    },
    {
      label: "Zapis do pliku",
      prop: "export",
      binary: true,
    },
    {
      label: "Nowe funkcje",
      prop: "beta",
      binary: true,
    },
    {
      label: "Raporty",
      prop: "reports",
      binary: true,
    },
    {
      label: "Archiwum",
      prop: "archive",
      binary: true,
    },
    {
      label: "Historia zmian",
      prop: "history",
      binary: true,
    },

    {
      label: "Dane podstawowe",
      prop: "basic",
    },
    {
      label: "Agenda",
      prop: "agenda",
    },
    {
      label: "Rozliczenia",
      prop: "settlements",
    },
    {
      label: "Uwagi",
      prop: "comments",
    },
    {
      label: "Demo",
      prop: "demo",
      binary: true,
      wide: true,
    },
  ];

  const authTemplates: any = [
    {
      name: "Recepcja",
      values: {
        view: 1,
        edit: 1,
        add: 0,
        delete: 0,
        lock: 0,
        search: 0,
        export: 0,
        beta: 0,
        reports: 0,
        archive: 0,
        history: 0,
        basic: 1,
        agenda: 1,
        settlements: 0,
        comments: 2,
      },
    },
    {
      name: "Sprzedaż",
      values: {
        view: 1,
        edit: 1,
        add: 1,
        delete: 0,
        lock: 0,
        search: 1,
        export: 1,
        beta: 0,
        reports: 1,
        archive: 0,
        history: 0,
        basic: 2,
        agenda: 2,
        settlements: 2,
        comments: 2,
      },
    },

    {
      name: "Pełny dostęp",
      values: {
        view: 1,
        edit: 1,
        add: 1,
        delete: 1,
        lock: 1,
        search: 1,
        export: 1,
        beta: 1,
        reports: 1,
        archive: 1,
        history: 1,
        basic: 2,
        agenda: 2,
        settlements: 2,
        comments: 2,
      },
    },
  ];

  async function handleSubmit(e: any) {
    e.preventDefault();
    let success = true;
    let result;
    try {
      const response = await fetch(
        `${URL}backend/inserts/set-calendar-auth.php`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(newAuthData),
        }
      );
      result = await response.json();
    } catch (result) {
      window.alert("Błąd zapisywania");
      success = false;
    }
    if (success) {
      if (result && result.error) {
        window.alert(result.error);
      } else {
        // setModal((prev: any) => ({ ...prev, show: false }));
        setJustSaved(true);
      }
    }
  }
  return !isAdminOrCreator ? (
    <div>Brak uprawnień</div>
  ) : (
    <form
      className="auth-settings"
      onSubmit={handleSubmit}
      onChange={() => setJustSaved(false)}
    >
      {authData ? (
        <>
          {godMode ? (
            <div style={{ color: "red", fontWeight: "bold" }}>
              GOD MODE ENABLED
            </div>
          ) : (
            <></>
          )}
          <div className="auth-heading">
            <div>Uprawnienia użytkowników</div>
            <select value={selectedUser} onChange={handleUserSelect}>
              <option hidden={true} id="0">
                Wybierz
              </option>
              {authData
                .filter((i: any) => i?.active)
                .map((item: any) => (
                  <option
                    key={item.user_id}
                    value={item.user_id || item.userID}
                  >
                    {item.first_name} {item.last_name}
                  </option>
                ))}
            </select>
          </div>
          {newAuthData ? (
            <div>
              <div
                style={{
                  padding: "5px 10px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                  border: "1px solid rgba(0,0,0,.3)",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3>Szablon</h3>
                <select
                  value={
                    newAuthData
                      ? authTemplates?.findIndex((template: any) => {
                          const allSame = Object.keys(template.values)
                            .map(
                              (key: any) =>
                                newAuthData?.[key] == template.values?.[key]
                            )
                            .reduce((a: Boolean, b: Boolean) => a && b, true);

                          return allSame;
                        })
                      : 0
                  }
                  onChange={(e) =>
                    setNewAuthData((prev: any) => {
                      const propValues = authTemplates[e.target.value].values;
                      return {
                        ...prev,
                        ...propValues,
                      };
                    })
                  }
                >
                  <option hidden={true} value={"custom"}>
                    własny
                  </option>
                  {authTemplates.map((template: any, index: number) => (
                    <option value={index}>{template.name}</option>
                  ))}
                </select>
              </div>
              <div className="auth-data-list">
                {authList.map((auth: any) => {
                  const options = auth?.binary ? optionTextBinary : optionText;
                  return (
                    <div key={auth.prop}>
                      <div>
                        <label htmlFor={auth.prop}>{auth.label}</label>
                      </div>
                      <div>
                        <select
                          name={auth.prop}
                          id={auth.prop}
                          value={Number(newAuthData?.[auth.prop])}
                          data-chosen={Number(newAuthData?.[auth.prop])}
                          onChange={(e) =>
                            setNewAuthData((prev: any) => ({
                              ...prev,
                              [auth.prop]: Number(e.target.value),
                            }))
                          }
                          style={
                            Number(newAuthData?.[auth.prop]) === 1
                              ? { fontWeight: "bold", color: "green" }
                              : Number(newAuthData?.[auth.prop]) === 2
                              ? { fontWeight: "bold", color: "rgb(0,170,120)" }
                              : { color: "brown" }
                          }
                        >
                          {options &&
                            options.map((option: any, index: any) => (
                              <option key={option} value={index}>
                                {option}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {newAuthData ? (
        <div>
          {justSaved ? (
            <div>Zapisano zmiany</div>
          ) : (
            <button className="auth-submit" type="submit">
              ZAPISZ
            </button>
          )}
        </div>
      ) : (
        <></>
      )}
    </form>
  );
}
