export default function HolidayOptions({
  setHolidayType,
  holidayType,
}: {
  setHolidayType: Function;
  holidayType?: number;
}) {
  return (
    <div className="holidayOptions">
      <ul>
        <li
          className={holidayType === 1 ? "active" : ""}
          onClick={() => setHolidayType(1)}
        >
          Urlop wypoczynkowy
        </li>
        <li
          className={holidayType === 7 ? "active" : ""}
          onClick={() => setHolidayType(7)}
        >
          Chorobowe
        </li>
        <li
          className={holidayType === 3 ? "active" : ""}
          onClick={() => setHolidayType(3)}
        >
          Urlop okolicznościowy
        </li>
        <li
          className={holidayType === 6 ? "active" : ""}
          onClick={() => setHolidayType(6)}
        >
          Dzień wolny za święto
        </li>
        <li
          className={holidayType === 5 ? "active" : ""}
          onClick={() => setHolidayType(5)}
        >
          Opieka nad dzieckiem
        </li>
        <li
          className={holidayType === 4 ? "active" : ""}
          onClick={() => setHolidayType(4)}
        >
          Urlop bezpłatny
        </li>
        <li
          className={holidayType === 9 ? "active" : ""}
          onClick={() => setHolidayType(9)}
        >
          Dzień wolny z nadgodzin
        </li>
        <li
          className={holidayType === 10 ? "active" : ""}
          onClick={() => setHolidayType(10)}
        >
          Urlop - inny
        </li>
        <li
          className={holidayType === 12 ? "active" : ""}
          onClick={() => setHolidayType(12)}
        >
          Krew
        </li>
      </ul>
    </div>
  );
}
