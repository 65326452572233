import { schema as employeesSchema } from "./Schemas/Employees/schema";
import { schema as errorsSchema } from "./Schemas/Errors/schema";
import {
  voucherPrep,
  schema as vouchersSchema,
} from "./Schemas/Vouchers/schema";
import { schema as problemTypesSchema } from "./Schemas/Errors/ProblemTypes/schema";
import { schema as salesDocumentTypesSchema } from "./Schemas/Errors/SalesDocuments/schema";
import { schema as purchaseInvoicesSchema } from "./Schemas/PurchaseInvoices/schema";
import { schema as bookingsSchema } from "./Schemas/BookingsLSI/schema";
import { schema as internalSessionsSchema } from "./Schemas/InternalSessions/schema";
import { schema as clientSchema } from "./Schemas/Clients/schema";
import { schema as balanceListSchema } from "./Schemas/BalanceListLSI/schema";
import { schema as deliveriesSchema } from "./Schemas/DeliveriesLSI/schema";
import { schema as arrivalsSchema } from "./Schemas/ArrivalsLSI/schema";
import { schema as fileSchema } from "./Schemas/Files/schema";
import { schema as productsSchema } from "./Schemas/ProductsLSI/schema";
import { schema as salesSchema } from "./Schemas/SalesLSI/schema";

import InvoicesView from "../experiments/InvoicesView";
import SessionsLSI from "../SessionsLSI";
import { genericRow } from "./Schemas/genericRow";
import { row as employeesRow } from "./Schemas/Employees/row";
import { row as paymentFormsRow } from "./Schemas/PaymentFormChange/row";
import { row as errorRow } from "./Schemas/Errors/row";
import { row as eventsRow } from "./Schemas/Events/row";
import { row as transferReceiptsRow } from "./Schemas/TransferReceipts/row";
import { row as vouchersRow } from "./Schemas/Vouchers/row";
import { row as receiptsLSIrow } from "./Schemas/ReceiptsLSI/row";
import { row as receiptsNewLSIrow } from "./Schemas/ReceiptsLSI/row-new";
import { row as bookingsRow } from "./Schemas/BookingsLSI/row";
import { row as purchaseInvoicesRow } from "./Schemas/PurchaseInvoices/row";
import { row as userRow } from "./Schemas/Users/row";
import { row as authRow } from "./Schemas/Auth/row";
import { row as sessionRow } from "./Schemas/InternalSessions/row";
import { row as clientRow } from "./Schemas/Clients/row";
import { row as balanceListRow } from "./Schemas/BalanceListLSI/row";
import { row as deliveriesRow } from "./Schemas/DeliveriesLSI/row";
import { row as arrivalsRow } from "./Schemas/ArrivalsLSI/row";
import { row as fileRow } from "./Schemas/Files/row";
import { row as productsRow } from "./Schemas/ProductsLSI/row";
import { row as salesRow } from "./Schemas/SalesLSI/row";

import errorsIcon from "../../images/icons/errors.svg";
import paymentFormsIcon from "../../images/icons/payment_form.svg";
import billIcon from "../../images/icons/bill.svg";
import cardsIcon from "../../images/icons/cards.svg";
import dataIcon from "../../images/icons/data.svg";
import employeesIcon from "../../images/icons/employees2.svg";
import roomsIcon from "../../images/icons/rooms.svg";
import eventsIcon from "../../images/icons/event_type.svg";
import keysIcon from "../../images/icons/keys.svg";
import usersIcon from "../../images/icons/person.svg";
import overpaymentIcon from "../../images/icons/overpayment2.svg";
import anniversaryIcon from "../../images/icons/anniversary.svg";
import invoicesIcon from "../../images/icons/invoices.png";
import sessionsIcon from "../../images/icons/sessions.svg";
import voucherIcon from "../../images/icons/voucher.svg";
import receiptIcon from "../../images/icons/receipt.svg";
import bookingIcon from "../../images/icons/booking.svg";
import settingsIcon from "../../images/icons/lab.png";
import accountingIcon from "../../images/icons/accounting.svg";
import deliveryIcon from "../../images/icons/delivery.svg";
import customersIcon from "../../images/icons/people.svg";
import balanceIcon from "../../images/icons/saldo.svg";
import arrivalsIcon from "../../images/icons/arrival.svg";
import productsIcon from "../../images/icons/coffee.svg";
import statsIcon from "../../images/icons/generic-data.svg";
import View from "./View";
import { row } from "./Schemas/Errors/ProblemTypes/row";
import { schema as receiptsSchema } from "./Schemas/ReceiptsLSI/schema";
import BalanceList from "./BalanceList";

export const registerTabSchema = [
  {
    name: "accounting",
    text: "Księgowe",
    icon: accountingIcon,
    sub: [
      {
        name: "errors",
        text: "Błędy",
        icon: errorsIcon,
        element: (
          <View
            heading="Błędy"
            source="errorTable"
            rowsPerPage={30}
            schema={errorsSchema}
            rowSchema={errorRow}
          />
        ),
      },
      {
        name: "payment-forms",
        text: "Zmiana formy płatności",
        icon: paymentFormsIcon,
        element: (
          <View
            heading="Zmiana formy płatności"
            source="paymentFormChange"
            rowsPerPage={20}
            rowSchema={paymentFormsRow}
          />
        ),
      },
      {
        name: "transfer-receipts",
        text: "Paragony przelewowe",
        icon: billIcon,
        element: (
          <View
            heading="Paragony przelewowe"
            source="transferReceipts"
            rowsPerPage={30}
            rowSchema={transferReceiptsRow}
          />
        ),
      },
      {
        name: "overpayments",
        text: "Nadpłaty",
        icon: overpaymentIcon,
      },

      {
        name: "anniversary-treatments",
        text: "Zabiegi rocznicowe",
        icon: anniversaryIcon,
      },
    ],
  },
  {
    name: "catalogs",
    text: "Kartoteki",
    icon: cardsIcon,
    sub: [
      {
        name: "employees",
        text: "Pracownicy",
        icon: employeesIcon,
        element: (
          <View
            heading="Pracownicy"
            source="employeesGeneric"
            rowsPerPage={50}
            schema={employeesSchema}
            rowSchema={employeesRow}
          />
        ),
      },
    ],
  },
  {
    name: "dictionaries",
    text: "Słowniki",
    icon: dataIcon,
    sub: [
      {
        name: "venues",
        text: "Sale",
        icon: roomsIcon,
        element: (
          <View
            heading="Sale"
            source="venues"
            rowsPerPage={30}
            rowSchema={genericRow}
          />
        ),
      },
      {
        name: "events",
        text: "Imprezy",
        icon: eventsIcon,
        element: (
          <View
            heading="Imprezy"
            source="events"
            rowsPerPage={30}
            rowSchema={eventsRow}
          />
        ),
      },
      {
        name: "problem-types",
        text: "Rodzaje problemów",
        element: (
          <View
            heading="Rodzaje problemów"
            source="problemTypes"
            rowsPerPage={30}
            schema={problemTypesSchema}
            rowSchema={row}
          />
        ),
      },
      {
        name: "sales-documents",
        text: "Dokumenty sprzaży",
        element: (
          <View
            heading="Dokumenty sprzedaży"
            source="salesDocumentTypes"
            rowsPerPage={30}
            schema={salesDocumentTypesSchema}
            rowSchema={genericRow}
          />
        ),
      },
      {
        name: "reporting-forms",
        text: "Formy zgłoszenia",
        element: (
          <View
            source="reportingFormTypes"
            rowsPerPage={30}
            rowSchema={genericRow}
          />
        ),
      },
      {
        name: "what-is-corrected",
        text: "Co podlega korekcie?",
        element: (
          <View
            source="whatIsCorrected"
            rowsPerPage={30}
            rowSchema={genericRow}
          />
        ),
      },
      {
        name: "correction-options",
        text: "Opcje korekty",
        element: (
          <View
            source="correctionSigned"
            rowsPerPage={30}
            rowSchema={genericRow}
          />
        ),
      },
    ],
  },
  {
    name: "lsi",
    text: "Dane LSI",
    icon: keysIcon,
    sub: [
      {
        name: "lsi-sessions",
        text: "Aktywne sesje LSI",
        icon: sessionsIcon,
        element: <SessionsLSI />,
      },
      {
        name: "purchase-invoices",
        text: "Faktury zakupowe",
        icon: billIcon,
        element: (
          <View
            source="purchase_invoices"
            rowsPerPage={30}
            schema={purchaseInvoicesSchema}
            rowSchema={purchaseInvoicesRow}
          />
        ),
      },
      {
        name: "lsi-sales-invoices",
        text: "Faktury sprzedażowe",
        icon: invoicesIcon,
        element: <InvoicesView sub={true} />,
      },
      {
        name: "lsi-receipts",
        text: "Paragony",
        icon: receiptIcon,
        element: (
          <View
            source="lsi_receipt_query_source"
            // params={{
            //   groupBy: (v: any) => v?.full_number,
            // }}
            dated={true}
            rowsPerPage={30}
            schema={receiptsSchema}
            rowSchema={receiptsNewLSIrow}
          />
        ),
      },
      {
        name: "vouchers",
        text: "Vouchery",
        icon: voucherIcon,
        element: (
          <View
            source="lsi_vouchers"
            // params={voucherPrep}
            rowsPerPage={30}
            schema={vouchersSchema}
            rowSchema={vouchersRow}
          />
        ),
      },
      {
        name: "lsi-clients",
        text: "Klienci",
        icon: customersIcon,
        element: (
          <View
            source="lsi_clients"
            rowsPerPage={20}
            schema={clientSchema}
            rowSchema={clientRow}
          />
        ),
      },
      {
        name: "lsi-bookings",
        text: "Rezerwacje pokoi",
        icon: bookingIcon,
        element: (
          <View
            source="lsi_bookings"
            rowsPerPage={20}
            schema={bookingsSchema}
            rowSchema={bookingsRow}
          />
        ),
      },
      {
        name: "lsi-balance-list",
        text: "Saldo lista",
        icon: balanceIcon,
        element: (
          <BalanceList />
          // <View
          //   source="lsi_balance_list"
          //   rowsPerPage={20}
          //   schema={balanceListSchema}
          //   rowSchema={balanceListRow}
          // />
        ),
      },
      {
        name: "lsi-sales-stats",
        text: "Sprzedaż",
        icon: statsIcon,
        element: (
          <View
            source="lsi_sales_stats"
            // rowsPerPage={20}
            schema={salesSchema}
            rowSchema={salesRow}
          />
        ),
      },
      {
        name: "lsi-products",
        text: "Produkty",
        icon: productsIcon,
        element: (
          <View
            source="lsi_products"
            rowsPerPage={20}
            schema={productsSchema}
            rowSchema={productsRow}
          />
        ),
      },
      {
        name: "lsi-deliveries",
        text: "Dostawy",
        icon: deliveryIcon,
        element: (
          <View
            source="lsi_deliveries"
            rowsPerPage={20}
            schema={deliveriesSchema}
            rowSchema={deliveriesRow}
          />
        ),
      },
      {
        name: "lsi-arrivals",
        text: "Przyjazdy",
        icon: arrivalsIcon,
        element: (
          <View
            source="lsi_incomings"
            rowsPerPage={100}
            schema={arrivalsSchema}
            rowSchema={arrivalsRow}
          />
        ),
      },
    ],
  },
  {
    name: "system",
    text: "System",
    icon: settingsIcon,
    sub: [
      {
        name: "sessions",
        text: "Aktywne sesje",
        icon: sessionsIcon,
        element: (
          <View
            source="sessions"
            schema={internalSessionsSchema}
            rowsPerPage={50}
            rowSchema={sessionRow}
          />
        ),
      },
      {
        name: "users",
        text: "Użytkownicy",
        icon: usersIcon,
        element: (
          <View
            source="userList"
            rowsPerPage={50}
            // schema={bookingsSchema}
            rowSchema={userRow}
          />
        ),
      },
      {
        name: "auth",
        text: "Uprawnienia",
        icon: usersIcon,
        element: <View source="auth" rowsPerPage={50} rowSchema={authRow} />,
      },
      {
        name: "files",
        text: "Pliki",
        icon: sessionsIcon,
        element: (
          <View
            source="getFiles"
            schema={fileSchema}
            rowsPerPage={50}
            rowSchema={fileRow}
          />
        ),
      },
    ],
  },
];
