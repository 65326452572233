import HamsterLoading from "../common/HamsterLoading";
import Info from "../common/Info";
import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import AttendanceSumYear from "./AttendanceSumYear";
import AttendanceSumYearHeadless from "./AttendanceSumYearHeadless";

export default function AnnualReports({
  date,
  location,
  groups,
  holidayTypes,
}: {
  date: any;
  location: any;
  groups: any;
  holidayTypes: any;
}) {
  const raw = useGeneric({
    name: "annualAttendanceReport",
    query: {
      //   month: date.month + 1,
      year: date.year,
      location: location === "biuro" ? "1" : "0",
    },
    limit: 99999,
    reload: true,
  });
  const data = !raw.loading ? raw.rows : undefined;
  // console.log(data);
  const isThisYear = date?.year === new Date()?.getFullYear();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow?.getDate() + 1);

  const { loading: hLoading, rows: hData } = useGeneric({
    name: "holidayCountAtDate",
    query: {
      dateString: isThisYear
        ? date?.year + tomorrow?.toISOString()?.slice(4, 10) // "-12-31" // new Date()?.toISOString()?.slice(0, 10)
        : date?.year + "-01-01",
    },
    // limit: 99999,
    // reload: true,
  });

  const holidayData = !hLoading && hData;

  const dataGroupped =
    data && groups
      ? groups.map((group: any) => ({
          ...group,
          data: data.filter((i: any) => i.group === group.id),
        }))
      : undefined;

  return raw.loading ? (
    <HamsterLoading />
  ) : data && data.length ? (
    <div>
      <div className="annual-reports">
        {isThisYear ? (
          <div style={{ textAlign: "center" }}>
            Stan na dzień {new Date()?.toLocaleDateString()} - na koniec dnia
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            Stan na koniec roku (31.12.{date?.year})
          </div>
        )}
        {dataGroupped.map((group: any) => (
          <div>
            <div className="group-name">{group.name}</div>
            <div style={{ padding: "0 10px" }} className="grid-table">
              <div className="grid-table-head">
                <div className="grid-table-row">
                  <div>Osoba</div>
                  <div>Dni wolne</div>
                  <div>Pozostały urlop</div>
                  <div>Zaległy urlop</div>
                  <div>Godz. pracy</div>
                  <div>Godz. url.</div>
                  <div>Suma godzin</div>
                  <div>Saldo roczne</div>
                </div>
              </div>
              {group.data.map((item: any) => {
                const ht = JSON.parse(item.holiday_types);
                const allHours = item.all_hours ? (
                  <>
                    <>{item.all_hours} h</>
                    {item.all_and_minutes ? (
                      <div style={{ marginLeft: "4px", fontSize: "76%" }}>
                        {" "}
                        {item.all_and_minutes} min
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                );
                const workHours = item.work_hours ? (
                  <>
                    <>{item.work_hours} h</>
                    {item.work_and_minutes ? (
                      <div style={{ marginLeft: "4px", fontSize: "76%" }}>
                        {" "}
                        {item.work_and_minutes} min
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                );
                const holidaySum =
                  holidayData &&
                  holidayData?.find((i: any) => i?.id === item?.employeeID);
                return (
                  <div className="grid-table-row">
                    <div>
                      {item.first_name} {item.last_name}
                    </div>
                    <div
                      className="hover-holiday-details"
                      style={{ position: "relative" }}
                    >
                      <div>{item.holidays ? item.holidays : <>-</>}</div>

                      <div className="holiday-details">
                        {holidayTypes.map((h: any) =>
                          ht?.[h?.id - 1] ? (
                            <div
                              //   className={h?.id ? "u" + h.id : ""}
                              style={{
                                display: "flex",
                                marginRight: "9px",
                                justifyContent: "space-between",
                                // background: h?.color,
                                // boxShadow: "0 1px 1px rgba(15,15,15,.3)",
                              }}
                            >
                              <div
                                style={{
                                  //   background: "rgb(255,255,255)",
                                  padding: "0 2px",
                                  marginRight: "2px",
                                }}
                              >
                                {h?.abbr}
                              </div>{" "}
                              <div style={{ fontWeight: "bold" }}>
                                {ht?.[h?.id - 1]}
                              </div>
                            </div>
                          ) : (
                            <></>
                          )
                        )}
                      </div>
                    </div>
                    <div>
                      {holidaySum?.remaining_holiday ? (
                        <>{holidaySum?.remaining_holiday}</>
                      ) : (
                        <>-</>
                      )}{" "}
                    </div>
                    <div>
                      {holidaySum?.from_last ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            color: "grey",
                          }}
                          title={
                            "Zostało z poprzedniego roku / było na 1.01: " +
                            holidaySum?.from_last
                          }
                        >
                          <div>
                            {Number(holidaySum?.from_last) -
                              Number(holidaySum?.this_year) >=
                            0 ? (
                              <span style={{ color: "black" }}>
                                {holidaySum?.from_last - holidaySum?.this_year}
                              </span>
                            ) : (
                              0
                            )}{" "}
                          </div>
                          <div>
                            z {holidaySum?.from_last}{" "}
                            <span
                              style={{
                                fontSize: "80%",
                                background: "#b6b6b6",
                                color: "white",
                                padding: "1px 3px",
                                display: "inline-block",
                              }}
                            >
                              {date?.year - 1}
                            </span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                      }}
                    >
                      {workHours}
                    </div>
                    <div>
                      {item.holiday_hours ? (
                        <>{item.holiday_hours} h</>
                      ) : (
                        <>-</>
                      )}{" "}
                    </div>
                    <div
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                        // fontWeight: "bold",
                      }}
                    >
                      {item.all_hours ? allHours : workHours}
                    </div>
                    <div>
                      <AttendanceSumYearHeadless
                        employeeID={item?.employeeID}
                        year={date.year}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : raw.loading ? (
    <div>Pobieranie danych</div>
  ) : (
    <div>Błąd pobierania</div>
  );
}
