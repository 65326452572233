import ReceiptDetails from "../../../calendar/ReceiptDetails";
import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";

export function schema(data: any, functions: any) {
  return {
    activeFilter: false,
    edit: false,
    view: true,
    viewContent: <ReceiptDetails id={data?.id}/>,

    filters: [
      //   {
      //     column: "seller_name",
      //     name: "Sprzedający",
      //   },
      //   {
      //     column: "status",
      //     name: "Status",
      //     element: "select",
      //     optionList: [
      //       {
      //         id: "wystawiona",
      //         name: "wystawiona",
      //       },
      //       {
      //         id: "opłacona",
      //         name: "opłacona",
      //       },
      //     ],
      //   },
      //   {
      //     column: "group",
      //     name: "Grupa",
      //     element: "select",
      //     getOptionsFrom: "employeeGroups",
      //   },
      //   {
      //     column: "name",
      //     name: "Imię i/lub nazwisko",
      //   },
    ],
  };
}
