import { useState } from "react";
import MapTable from "../../../../common/MapTable";
import useGeneric from "../../../../dataFetch/useGeneric";
import prettyDate from "../../../../functions/prettyDate";
import prettyNumber from "../../../../functions/prettyNumber";
import Filters from "../../../Filters";
import { sqlCurrentDate } from "../../../../functions/tools";

export default function TransactionList({
  data,
  hide,
}: {
  data: any;
  hide: Boolean;
}) {
  const startMonth = new Date();
  startMonth.setDate(1);
  const endMonth = new Date();
  endMonth.setMonth(endMonth.getMonth() + 1);
  endMonth.setDate(0);
  const sm = startMonth.toISOString().slice(0, 10);
  const em = endMonth.toISOString().slice(0, 10);

  const [query, setQuery] = useState({
    product_id: data?.nr_produktu,
    date_start: sm,
    date_end: em,
  });
  const { rows, loading } = useGeneric({
    name: "lsi_transactions",
    query: query,
  });

  const transactions = !loading && rows;

  const sums = !loading &&
    rows?.length && {
      amount: prettyNumber(
        rows
          ?.map((r: any) => Number(r?.Amount))
          ?.reduce((a: any, b: any) => a + b, 0),
        { round: 1 }
      ),
      net: prettyNumber(
        rows
          ?.map((r: any) => Number(r?.ValueN))
          ?.reduce((a: any, b: any) => a + b, 0),
        { round: 2, postfix: "zł" }
      ),
      gross: prettyNumber(
        rows
          ?.map((r: any) => Number(r?.ValueB))
          ?.reduce((a: any, b: any) => a + b, 0),
        { round: 2, postfix: "zł" }
      ),
    };

  return (
    <div style={hide ? { display: "none" } : {}}>
      <div
        style={{
          display: "flex",
          paddingBottom: "18px",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        {/* <div>
          <button
            onClick={() =>
              setQuery((prev: any) => {
                const d1 = new Date(prev?.date_start);
                const d2 = new Date(prev?.date_end);
                d1.setMonth(d1.getMonth() - 1);
                d2.setMonth(d2.getMonth() - 1);

                return {
                  ...prev,
                  date_start: d1?.toISOString()?.slice(0, 10),
                  date_end: d2?.toISOString()?.slice(0, 10),
                };
              })
            }
          >
            {"<"}
          </button>
          <button>{">"}</button>
        </div> */}
        <Filters
          className="map-table-filters"
          schema={[
            {
              column: "date_start",
              name: "Od",
              type: "date",
              exact: true,
              value: sm,
            },
            {
              column: "date_end",
              name: "Do",
              type: "date",
              exact: true,
              value: em,
            },
          ]}
          setQuery={setQuery}
        />
        {!loading && rows?.length ? (
          <div className="map-table-sums">
            <div>
              <div>ILOŚĆ</div>
              <div>{sums.amount}</div>
            </div>
            <div>
              <div>SUMA NETTO</div>
              <div>{sums.net}</div>
            </div>
            <div>
              <div>SUMA BRUTTO</div>
              <div>{sums.gross}</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <MapTable
        options={{ numbers: true }}
        rows={transactions}
        columns={[
          {
            name: "Data",
            prop: "Data",
            transform: (v: any) => (
              <div style={{ fontWeight: "bold" }}>
                {new Date(v)?.toLocaleDateString()}
              </div>
            ),
          },
          {
            name: "Typ dokumentu",
            prop: "DokTypOpis",
          },
          {
            name: "Nr dokumentu",
            prop: "DokNr",
          },
          {
            name: "Klient",
            prop: "Firma",
          },
          {
            name: "Ilość",
            prop: "Amount",
            transform: (v: any) => prettyNumber(v, { round: 1 }),
          },
          {
            name: "Wartość netto",
            prop: "ValueN",
            transform: (v: any) => prettyNumber(v, { round: 2, postfix: "zł" }),
          },
          {
            name: "Wartość brutto",
            prop: "ValueB",
            transform: (v: any) => prettyNumber(v, { round: 2, postfix: "zł" }),
          },
        ]}
      />
    </div>
  );
}
