export default function MapTable({
  rows,
  columns,
  options,
}: {
  rows: any[];
  columns: {
    name: string;
    prop: string;
    transform?: Function;
  }[];
  options?: {
    numbers?: Boolean;
  };
}) {
  return (
    rows &&
    columns && (
      <table className="data-table midi">
        <thead>
          <tr>
            {options?.numbers ? <th>Lp.</th> : <></>}
            {columns.map((c: any) => (
              <th>{c?.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((r: any, rowIndex: number) => {
            return (
              <tr>
                {options?.numbers ? <td>{rowIndex +1}</td> : <></>}
                {columns?.map((c: any) => (
                  <td>
                    {c?.transform ? c?.transform(r?.[c?.prop]) : r?.[c?.prop]}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    )
  );
}
