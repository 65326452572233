import { useState } from "react";
import magnifierIcon from "../../images/icons/magnifier.svg";
import useGeneric from "../dataFetch/useGeneric";
import HybridResults from "./HybridResults";
import { createContext } from "react";
import { Field, useFormikContext } from "formik";
import { nameTypes } from "../dataFetch/genericCaseMap";

export const HybridContext = createContext<any>({
  resultElement: false,
  resultUpdate: false,
  foundRows: [],
});

export default function HybridField({
  name,
  db,
  query,
  type,
  resultElement,
  resultUpdate,
  minLength,
}: {
  name: string;
  db: keyof typeof nameTypes | "";
  query: Function;
  type: string;
  resultElement: Function;
  resultUpdate: Function;
  minLength?: number;
}) {
  const { setFieldValue, values } = useFormikContext<any>();
  const [inputText, setInputText] = useState(values ? values[name] : "");
  const [dbQuery, setDBQuery] = useState("");

  const [resultsWindow, setResultsWindow] = useState(true);

  const fetchDB = useGeneric({
    name: dbQuery ? db : "",
    limit: 20,
    offset: 0,
    query: query(dbQuery),
    update: dbQuery,
    onlyOnFocus: true,
  });

  const foundRows = !fetchDB?.loading ? fetchDB.rows : undefined;

  return (
    <HybridContext.Provider
      value={{
        resultElement: resultElement,
        resultUpdate: resultUpdate,
        foundRows: foundRows,
        setResultsWindow: setResultsWindow,
      }}
    >
      <div className="hybrid-search-wrap">
        {/*<input
          type={type}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
          }}
        />*/}
        <Field
          type={type}
          name={name}
          value={inputText}
          minLength={minLength}
          onChange={(e: any) => {
            setInputText(e.target.value);
            setFieldValue(name, e.target.value);
          }}
        />
        <div
          className="search-button"
          onClick={() => {
            setDBQuery(inputText);
            setResultsWindow(true);
          }}
        >
          <img src={magnifierIcon} />
        </div>
        {foundRows && resultsWindow ? <HybridResults /> : <></>}
      </div>
    </HybridContext.Provider>
  );
}
