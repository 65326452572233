import { useState } from "react";
import insertGeneric from "../../dataFetch/insertGeneric";
import HolidayCell from "../table/HolidayCell";
import AttEvents from "./AttEvents";
import minutesToJSX from "../../functions/minutesToJSX";
import SumRow from "./SumRow";
import HolidayOptions from "./HolidayOptions";

export default function AttRow({
  date,
  holidays,
  holidaysSuggested,
  employeeData,
  location,
  setUpdate,
  setSmallUpdate,
  items,
  selectedPerson,
  index,
  arr,
  timeSum,
  setTimeSum,
  add,
  setAdd,
  bulkAdd,
  bulkType,
  bulkDrag,
  setBulkDrag,
  bulkList,
  setBulkList,
}: {
  date: any;
  holidays?: any[];
  holidaysSuggested: any[] | undefined;
  employeeData?: any;
  location: string;
  setUpdate: Function;
  setSmallUpdate: Function;
  items: any[];
  selectedPerson: string | false;
  index: number;
  arr: any[];
  timeSum: number;
  setTimeSum: Function;
  add: any;
  setAdd: Function;
  bulkAdd?: Boolean;
  bulkType?: number;
  bulkDrag?: false | "remove" | "add";
  setBulkDrag?: Function;
  bulkList?: any;
  setBulkList?: Function;
}) {
  const { obj: d, holiday } = date;

  const holidayColors = [
    "#56d156",
    "greenyellow",
    "#60c05a",
    "lightgrey",
    "pink",
    "orange",
    "#dceb55",
    "whitesmoke",
    "almond",
    "#e97a7a",
    "whitesmoke",
    "#f64444",
    "red",
  ];

  const [holidayOptions, setHolidayOptions] = useState(false);

  const datumCor = new Date(d);
  datumCor.setHours(10);
  const dateSQL = datumCor.toISOString().slice(0, 10);

  const isHoliday = holidays
    ? holidays.find((h: any) => h.date === dateSQL)
    : false;

  const isHolidaySuggested = holidaysSuggested
    ? holidaysSuggested.find((h: any) => h.date === dateSQL)
    : false;

  const currentHoliday = holidays
    ? holidays.filter((h: any) => h.date === dateSQL)[0]
    : false;

  const currentHolidaySuggested = holidaysSuggested
    ? holidaysSuggested.filter((h: any) => h.date === dateSQL)[0]
    : false;

  function setHolidayType(holidayType: number) {
    if (employeeData && employeeData.id) {
      insertGeneric("holidays", {
        date: dateSQL,
        employeeID: employeeData.id,
        type: holidayType,
        status: 1,
      });
    }
    setSmallUpdate((prev: number) => prev + 1);
    setHolidayOptions(false);
  }

  const dateIn = items;
  //   const prevIn = arr[index - 1] ? arr[index - 1].items : [];
  //   let nextIn = arr[index + 1] ? arr[index + 1].items : [];
  //   const nextIn2 = arr[index + 2] ? arr[index + 2].items : [];

  function addOrRemove(click: Boolean, e: any) {
    {
      if (setBulkList && e.button === 0) {
        if (click) {
          // console.log("KLAK");
          setBulkList((prev: any) => ({
            ...prev,
            [dateSQL]: {
              selected: !prev?.[dateSQL]?.selected,
              type: prev?.[dateSQL]?.selected ? undefined : bulkType,
            },
          }));
        } else if (bulkDrag === "add") {
          setBulkList((prev: any) => ({
            ...prev,
            [dateSQL]: { selected: true, type: bulkType },
          }));
        } else if (bulkDrag === "remove") {
          setBulkList((prev: any) => ({
            ...prev,
            [dateSQL]: { selected: false, type: false },
          }));
        }
      }
    }
  }

  const holidayTypes: any = {
    1: "urlop wyp.",
    7: "chorobowe",
    3: "okolicznościowy",
    6: "za święto",
    5: "opieka nad dzieckiem",
    4: "urlop bezpłatny",
    9: "z nadgodzin",
    10: "inny",
    12: "krew",
  };

  return (
    <>
      <tr
        key={d.getDate()}
        className={
          (!dateIn.length ? "empty " : "") +
          (d.getDate() === new Date().getDate() &&
          d.getMonth() === new Date().getMonth() &&
          d.getFullYear() === new Date().getFullYear()
            ? "today"
            : "") +
          (isHoliday ? " holiday" : "") +
          (d.getDay() === 0 ? " weekEnd" : "")
        }
        style={bulkAdd ? { userSelect: "none" } : {}}
      >
        <td
          className={
            (d.getDay() === 0 ? "sunday" : "") +
            (holiday ? " stateHoliday" : "")
          }
        >
          <div className="date">
            <div className="dayOfWeek">
              {d?.toLocaleDateString("pl-PL", {
                weekday: "short",
              })}
            </div>
            <div className="dayNumber">
              {d?.toLocaleDateString("pl-PL", {
                day: "2-digit",
              })}
            </div>
          </div>
        </td>

        <td>
          <AttEvents
            events={items}
            t={1}
            date={d}
            setUpdate={setUpdate}
            location={location}
            selectedPerson={selectedPerson}
            employeeData={employeeData}
          />
        </td>
        <td>
          <AttEvents
            events={items}
            t={2}
            date={d}
            setUpdate={setUpdate}
            location={location}
            selectedPerson={selectedPerson}
            employeeData={employeeData}
          />
        </td>
        <td>
          {items && items?.length ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {items?.map((i: any) => (
                <SumRow
                  event={i}
                  date={d}
                  timeSum={timeSum}
                  setTimeSum={setTimeSum}
                  add={add}
                  setAdd={setAdd}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </td>
        <td
          //   style={{ display: "block", position: "relative" }}
          className={
            (isHoliday || isHolidaySuggested ? "holiday" : "") +
            (bulkAdd ? " bulk-add-td" : "")
          } // "empty"}
        >
          {bulkAdd ? (
            <div
              className={
                "bulk-add-cell" + (bulkList?.[dateSQL]?.selected ? " sel" : "")
              }
              style={{
                background: holidayColors?.[bulkList?.[dateSQL]?.type - 1],
              }}
              onMouseDown={(e: any) => {
                e.preventDefault();
                if (setBulkDrag) {
                  if (bulkList?.[dateSQL]?.selected === true) {
                    setBulkDrag("remove");
                  } else {
                    setBulkDrag("add");
                  }
                }
              }}
              onMouseUp={() => {
                if (setBulkDrag) {
                  setBulkDrag(false);
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              onMouseMove={(e) => {
                addOrRemove(false, e);
              }}
              onClick={(e) => {
                addOrRemove(true, e);
              }}
            >
              {holidayTypes?.[bulkList?.[dateSQL]?.type]}
            </div>
          ) : (
            <HolidayCell
              isHoliday={isHoliday}
              isHolidaySuggested={isHolidaySuggested}
              currentHoliday={currentHoliday}
              currentHolidaySuggested={currentHolidaySuggested}
              employeeData={employeeData}
              setHolidayOptions={setHolidayOptions}
              setSmallUpdate={setSmallUpdate}
              dateSQL={dateSQL}
              colors={holidayColors}
            />
          )}
        </td>
      </tr>
      {holidayOptions ? (
        <HolidayOptions setHolidayType={setHolidayType} />
      ) : (
        <></>
      )}
    </>
  );
}
