import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import { objectOrJSONparse } from "../../../functions/tools";
import { registerSchema } from "../schemaType";
import expandIcon from "../../../../images/icons/expand.svg";

export function schema(data: any, functions: any, auth: any): registerSchema {
  return {
    name: "lsi_clients",
    edit: false,
    activeFilter: false,
    filters: [
        {
            column: "multiQuery",
            name:"Klient"
        }
    ],
    defaultParams: {
      name: "lsi_clients",
      //   order: {
      //     by: "last_online",
      //     dir: "DESC",
      //   },
    },
  };
}
