import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import PurchaseInvoiceView from "./PurchaseInvoiceView";

export function schema(data: any, functions: any) {
  return {
    filters: [
      {
        column: "seller_name",
        name: "Sprzedający",
      },
      {
        column: "status",
        name: "Status",
        element: "select",
        optionList: [
          {
            id: "wystawiona",
            name: "wystawiona",
          },
          {
            id: "opłacona",
            name: "opłacona",
          },
        ],
      },
    ],
    edit: false,
    activeFilter: false,
    remove: false,
    view: true,
    viewContent: <PurchaseInvoiceView data={data} />,
  };
}
