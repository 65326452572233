import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import ViewProduct from "./ViewProduct";

export function schema(data: any, functions: any) {
  const years = [];
  const now = new Date();

  for (let i = now.getFullYear(); i > 2012; i--) {
    years.push(i);
  }
  return {
    activeFilter: false,
    edit: false,
    showFilters: true,
    view: true,
    viewContent: <ViewProduct data={data}/>,
    filters: [
      {
        column: "rok",
        name: "Rok",
        element: "select",
        visible: true,
        optionList: years?.map((y: number) => ({
          id: y,
          name: y,
        })),
      },
      {
        column: "miesiac",
        name: "Miesiąc",
        element: "select",
        visible: true,
        optionList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map(
          (m: number) => ({
            id: m,
            name: new Date(2000, m - 1, 15)?.toLocaleDateString("pl-PL", {
              month: "long",
            }),
          })
        ),
      },
      {
        column: "Grupa",
        name: "Grupa",
        element: "select",
        getOptionsFrom: "lsi_product_groups",
      },
    //   { column: "n", name: "Nazwa", element: "input" },
    ],
  };
}
