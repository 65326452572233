import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";

export function schema(data: any, functions: any) {
  return {
    name: "lsi_products",
    activeFilter: false,
    edit: false,
    filters: [
      {
        column: "wysw_w_posie",
        name: "POS",
        element: "select",
        optionList: [
          { id: 1, name: "tak" },
          { id: 0, name: "nie" },
        ],
      },
    
      {
        column: "Grupa",
        name: "Grupa",
        element: "select",
        getOptionsFrom: "lsi_product_groups",
      },
      { column: "n", name: "Nazwa", element: "input" },
    ],
    defaultParams: {
      name: "lsi_products",
      //   order: {
      //     by: "last_online",
      //     dir: "DESC",
      //   },
    },
  };
}
